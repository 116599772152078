exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-work-all-jsx": () => import("./../../../src/templates/WorkAll.jsx" /* webpackChunkName: "component---src-templates-work-all-jsx" */),
  "component---src-templates-work-detail-page-template-jsx": () => import("./../../../src/templates/WorkDetailPageTemplate.jsx" /* webpackChunkName: "component---src-templates-work-detail-page-template-jsx" */)
}

